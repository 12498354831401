<template>
  <validation-provider :name="label||placeholder" :rules="rules" v-slot="validationContext" :vid="vid">
    <b-form-group :label-cols-sm="labelCols" :description="description" :label="(label||'')+(isRequired&&(label.length>0) ? ' *':'')" :label-for="id">
      <b-form-datepicker :name="id" :date-format-options="displayFormat" locale="id" :placeholder="placeholder" :id="id" :value="valueFormat" @input="handleInput" v-bind="inputAttrs" :aria-describedby="`${id}-feedback`" :state="getValidationState(validationContext)"></b-form-datepicker>
      <b-form-invalid-feedback :id="`${id}-feedback`">{{validationContext.errors[0]}}</b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script>
import id from "vee-validate/dist/locale/id.json"
import { localize, extend, ValidationProvider } from 'vee-validate';
import * as rules from "vee-validate/dist/rules";
import {BFormGroup,BFormDatepicker,BFormInvalidFeedback} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'
import isUndefined from 'lodash/isUndefined'

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
localize('id', id)

export default {
  name:'FormDate',
  props:{
    label:{
      type:String,
      default:''
    },
    value:[String,Number,Object],
    placeholder:{
      type:String,
      default:''
    },
    rules:Object,
    description:String,
    vid:String,
    labelCols:{
      type:Number,
      default:0
    }
  },
  components:{
    BFormGroup,
    BFormDatepicker,
    ValidationProvider,
    BFormInvalidFeedback
  },
  data(){
    return {
      id:'',
      displayFormat:{
        year:'numeric',
        month:'short',
        day:'2-digit'
      }
    }
  },
  computed:{
    inputAttrs(){
      const attrs = JSON.parse(JSON.stringify(this.$attrs))
      delete attrs.rules
      delete attrs.description
      delete attrs.vid
      delete attrs.locale
      return attrs
    },
    isRequired(){
      const rule = this.rules
      if(isUndefined(rule)||isUndefined(rule.required)) return false
      else return this.rules.required
    },
    valueFormat(){
      return this.$moment(this.value).format('YYYY-MM-DD')
    }
  },
  methods:{
    handleInput(e){
      const time = this.$moment().format('HH:mm:ss')
      const res = this.$moment(e+' '+time).toISOString()
      if(e) this.$emit('input', res)
      else this.$emit('input', e)
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? (valid ? null : false) : null;
    },
  },
  created(){
    this.id = uuidv4()
  }
}
</script>

<style>

</style>