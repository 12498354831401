<template>
  <b-link v-if="!isEdit" :class="{'dotted': !disabled, 'red': displayColor}" @click="editing">{{value | formatNumber}}</b-link>
  <!-- <b-form-input class="text-right" required autofocus v-bind="$attrs" v-on="listeners" @blur="onBlur" @keydown.native="enterHandler" v-else type="number" :value="value" @input="handleInput"></b-form-input> -->
  <c-cleave
    v-else
    ref="cleave"
    :value="value"
    @input="handleInput"
    v-bind="$attrs"
    v-on="listeners"
    :options="cleaveOptions"
    @blur="onBlur"
    @keydown.native="enterHandler"
    :class="['form-control',`form-control-${size}`]"
  ></c-cleave>

</template>

<script>
import {BLink} from 'bootstrap-vue'
import CCleave from './cleave'

export default {
  name:'InlineInputNumber',
  props:{
    value:[String,Number],
    disabled:Boolean,
    size:{
      type:String,
      default:'md'
    },
    isRequired:Boolean
  },
  components:{BLink,CCleave},
  data(){
    return {
      isEdit:false,
      cleaveOptions:{
        numeral: true,
        numeralThousandsGroupStyle:'thousand',
        numeralDecimalScale: 4
      },
    }
  },
  watch:{
    isEdit(val){
      if(val == true) {
        this.$nextTick(() => {
          this.$refs.cleave.$el.select()
        })
      }
    }
  },
  computed:{
    listeners(){
      const listeners = JSON.parse(JSON.stringify(this.$listeners))
      delete listeners.input
      delete listeners.blur
      delete listeners.keydown
      return listeners
    },
    displayColor(){
      if(this.isRequired && !this.value) return true
      else return false
    }
  },
  methods:{
    editing(){
      if(!this.disabled) this.isEdit = true
    },
    handleInput(e){
      this.$emit('input', e)
      this.$emit('onValueChanged', e)
    },
    enterHandler(e){
      if (e.which==13) {
        this.$refs.cleave.$el.blur()
      }
    },
    onBlur(){
      this.isEdit = false
      if(!this.value) this.$emit('input', 0)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import 'src/assets/scss/variables/_variables.scss';

  .dotted {
      border-bottom: 1px dotted $primary;
      text-decoration: none;
  }

  .red {
    color: red;
    border-bottom: 1px dotted red !important;
  }
</style>