<template>
  <validation-observer ref="observer">
    <b-form ref="form" @submit.prevent="onSubmit">
      <slot />
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import { BForm } from 'bootstrap-vue'

export default {
  name:'CForm',
  components:{
    ValidationObserver,
    BForm
  },
  methods:{
    onSubmit(params=null){
      this.$refs.observer.validate().then(success => {
        if(success) {
          this.$emit('submit', params)
        }
      })
    },
    validationCheck(){
      return new Promise((resolve,reject) => {
        this.$refs.observer.validate().then(success => {
          if(success) resolve(true)
          else reject(false)
        })
      })
    },
    reset(){
      this.$refs.form.reset()
    }
  }
}
</script>

<style>

</style>