<template>
  <div>
    <c-card title="Tambah Stok Opname">
      <template #action>
        <c-button :to="{name:'stockopnames'}" variant="outline-primary">Kembali</c-button>
        <c-button @click="$refs.form.onSubmit()" :loading="buttonloading">Simpan</c-button>
      </template>
      <c-form ref="form" @submit="confirmSubmit">
        <b-row cols-sm="2" cols-xl="4">
          <b-col>
            <form-input readonly label="No Nota" placeholder="Generate Otomatis" v-model="code"></form-input>
          </b-col>
          <b-col>
            <form-date label="Tanggal" v-model="formData.date" :rules="{required: true}"></form-date>
          </b-col>
          <b-col>
            <c-button @click="$refs.itemModal.show()" class="mt-2" block><feather-icon icon="SearchIcon"></feather-icon> Cari Produk</c-button>
          </b-col>
        </b-row>
        <b-table-simple bordered table-class="text-center" class="mt-2 mb-2" responsive small outlined>
          <b-thead>
            <b-tr>
              <b-th rowspan="3">Merek</b-th>
              <b-th rowspan="3">Artikel</b-th>
              <b-th rowspan="3">Warna</b-th>
              <b-th rowspan="3">Harga</b-th>
              <b-th :colspan="(sizeRows.length||1)*2">Ukuran</b-th>
              <b-th colspan="2" rowspan="2">Jml</b-th>
              <b-th rowspan="3">Total</b-th>
              <b-th rowspan="3"></b-th>
            </b-tr>
            <b-tr>
              <b-th colspan="2" v-for="(v,k) in sizeRows" :key="k">{{v.size_name}}</b-th>
            </b-tr>
            <b-tr>
              <template v-for="(v,k) in sizeRows">
                <b-th :key="'aa'+k">Sistem</b-th>
                <b-th :key="'bb'+k">Sekarang</b-th>
              </template>
              <b-th>Sistem</b-th>
              <b-th>Sekarang</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(v,i) in details" :key="i">
              <b-td>{{v.brand_name}}</b-td>
              <b-td>{{v.code}}</b-td>
              <b-td>{{v.color_name}}</b-td>
              <b-td>
                <inline-input-number style="width:10rem;" @onValueChanged="qtySizeChanged(i)" v-model.number="v.price"></inline-input-number>
              </b-td>
              <template v-for="(x,k) in sizeRows">
                <b-td :key="`a${k}`">
                  <template v-for="(s,l) in v.sizes">
                    <span v-if="x.id_size===s.id_size" :key="x.id_size+'~'+l">{{s.qty_system}}</span>
                  </template>
                </b-td>
                <b-td :key="`b${k}`">
                  <template v-for="(s,l) in v.sizes">
                    <inline-input-number style="width:5rem;" @onValueChanged="qtySizeChanged(i)" v-if="x.id_size===s.id_size" :key="x.id_size+'~'+l" v-model.number="s.qty_opname"></inline-input-number>
                  </template>
                </b-td>
              </template>
              <b-td>{{v.qty_system}}</b-td>
              <b-td>{{v.qty_opname}}</b-td>
              <b-td>Rp.{{v.price_total | formatNumber}}</b-td>
              <b-td>
                <c-button size="sm" variant="outline-danger" @click="details.splice(i, 1)">X</c-button>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <b-row>
          <b-col sm="12" xl="5">
            <form-textarea v-model="formData.note" label="Catatan"></form-textarea>
          </b-col>
          <b-col sm="12" xl="3" offset-xl="4">
            <form-number disabled class="w-50" :value="totalSystem" label="Stok Sistem"></form-number>
            <form-number disabled class="w-50" :value="totalOpname" label="Stok Opname"></form-number>
            <form-number disabled class="w-50" :value="totalDiffQty" label="Selisih Stok"></form-number>
            <form-number disabled class="w-50" :value="totalDiffPrice" label="Total Selisih"></form-number>
          </b-col>
        </b-row>
        <button type="submit" hidden></button>
      </c-form>
    </c-card>
    <!-- MODAL CARI PRODUK -->
    <b-modal lazy ref="itemModal" hide-footer size="xl">
      <item-index only-active-filter non-sale is-modal @importingfn="importing"></item-index>
    </b-modal>
  </div>
</template>

<script>
import CCard from '@/utils/components/CCard.vue'
import CButton from '@/utils/components/CButton.vue'
import CForm from '@/utils/components/CForm.vue'
import {BTableSimple,BThead,BTr,BTh,BModal,BTbody,BTd} from 'bootstrap-vue'
import FormInput from '@/utils/components/FormInput.vue'
import FormDate from '@/utils/components/FormDate.vue'
import FormTextarea from '@/utils/components/FormTextarea.vue'
import FormNumber from '@/utils/components/FormNumber.vue'
import ItemIndex from '../items/Index2.vue'

import findIndex from 'lodash/findIndex'
import flatMap from 'lodash/flatMap'
import sumBy from 'lodash/sumBy'
import sortBy from 'lodash/sortBy'
import InlineInputNumber from '@/utils/components/InlineInputNumber.vue'
import ScannerDetector from 'js-scanner-detection'

export default {
  components: { CCard, CButton, CForm, FormInput, FormDate, BTableSimple,BThead,BTr,BTh, FormTextarea, FormNumber, BModal, ItemIndex, BTbody, BTd, InlineInputNumber },
  data(){
    const formData = {
      date: this.$moment(),
      note: '',
    }
    const onComplete = (barcode) => {
      this.barcodeScanned(barcode)
    }
    const options = {
      onComplete,
      stopPropagation: true,
      preventDefault: false,
    }

    return {
      formData,
      code:'',
      supplier:null,
      buttonloading: false,
      details:[],
      scannerDetect : new ScannerDetector(options),
    }
  },
  computed:{
    sizeRows(){
      const dt = flatMap(this.details,n => n.sizes)
      let result = []
      for (let i = 0; i < dt.length; i++) {
        const el = dt[i];
        if(findIndex(result,{id_size: el.id_size})<0)
          result.push({
            id_size: el.id_size,
            size_name: el.size_name,
          })
      }
      return sortBy(result,['size_name'])
    },
    qtySystem(){
      const total = sumBy(this.details,'qty_system')
      return total
    },
    qtyOpname(){
      const total = sumBy(this.details,'qty_opname')
      return total
    },
    totalPriceOld(){
      return sumBy(this.details,'price_old')
    },
    totalPrice(){
      return sumBy(this.details,'price_total')
    },
    totalSystem(){
      let total = 0
      for (let i = 0; i < this.details.length; i++) {
        const element = this.details[i];
        total += sumBy(element.sizes,'qty_system')
      }
      return total
    },
    totalOpname(){
      let total = 0
      for (let i = 0; i < this.details.length; i++) {
        const element = this.details[i];
        total += sumBy(element.sizes,'qty_opname')
      }
      return total
    },
    totalDiffQty(){
      return Math.abs(this.totalSystem - this.totalOpname)
    },
    totalDiffPrice(){
      return this.totalPrice
    }
  },
  methods:{
    confirmSubmit(){
      this.confirmation(`submitForm`)
    },
    async submitForm(){
      const vm = this
      vm.buttonloading = true
      const formData = JSON.parse(JSON.stringify(vm.formData))
      formData.details = vm.details
      formData.qty_system = vm.qtySystem
      formData.qty_opname = vm.qtyOpname
      formData.qty_different = vm.totalDiffQty
      formData.total_different = vm.totalDiffPrice
      this.$http.post(`v1/stock_opnames/create`,formData).then(() => {
        vm.notify(`Data Berhasil Disimpan!`)
        vm.$router.push({name:'stockopnames'})
        vm.buttonloading = false
      }).catch((error) => {
        vm.buttonloading = false
        vm.handleError(error)
      })
    },
    async barcodeScanned(barcode){
      try {
        const vm = this
        const {data} = await this.$http.get(`v1/items/findbarcode`,{params:{code:barcode}})
        let imported = JSON.parse(JSON.stringify(this.details))
        const index = findIndex(imported,{id_item: data.id_item, id_color: data.id_color})
        if(index<0){
          imported.push({
            code: data.code_barcode,
            id_item: data.id_item,
            id_brand: data.id_brand,
            brand_name: data.brand_name,
            id_color: data.id_color,
            color_name: data.color_name,
            price_old: data.purchase_price * data.stock,
            price: data.purchase_price,
            qty_system: data.stock,
            qty_opname: data.stock,
            price_total: data.purchase_price * data.stock,
            sizes:[
              {
                id_variant: data.id_variant,
                id_size: data.id_size,
                size_name: data.size_name,
                qty_system: data.stock,
                qty_opname: data.stock
              }
            ]
          })
        } else {
          const indexSize = findIndex(imported[index].sizes,{id_variant:data.id_variant})
          if(indexSize<0) {
            imported[index].sizes.push({
              id_variant: data.id_variant,
              id_size: data.id_size,
              size_name: data.size_name,
              qty_system: data.stock,
              qty_opname: data.stock
            })
          }
          vm.$nextTick(()=>{
            vm.qtySizeChanged(index)
          })

        }
        this.$set(this,'details',imported)
      } catch (error) {
        this.handleError(error)
      }
    },
    importing(data){
      let imported = JSON.parse(JSON.stringify(this.details))
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        let itemcolor = []
        for (let x = 0; x < item.item_variants.length; x++) {
          const variant = item.item_variants[x];
          if(variant.checked) {
            // console.log(variant)
            if(!itemcolor.includes(variant.id_color) && findIndex(imported,{id_item: item.id, id_color: variant.id_color})<0) {
              itemcolor.push(variant.id_color)
              imported.push({
                code: item.code,
                id_item: item.id,
                id_brand: item.id_brand,
                brand_name: item.brand_name,
                id_color: variant.id_color,
                color_name: variant.color_name,
                price_old: variant.purchase_price * variant.stock,
                price: variant.purchase_price,
                qty_system: variant.stock,
                qty_opname: variant.stock,
                // price_total: variant.purchase_price * variant.stock,
                price_total: Math.abs(variant.stock - variant.stock) * variant.purchase_price,
                sizes:[
                  {
                    id_variant: variant.id,
                    id_size: variant.id_size,
                    size_name: variant.size_name,
                    qty_system: variant.stock,
                    qty_opname: variant.stock
                  }
                ]
              })
            } else {
              const index = findIndex(imported,{id_item: item.id, id_color: variant.id_color})
              if(findIndex(imported[index].sizes,{id_size: variant.id_size})<0)
                imported[index].sizes.push({
                  id_variant: variant.id,
                  id_size: variant.id_size,
                  size_name: variant.size_name,
                  qty_system: variant.stock,
                  qty_opname: variant.stock
                })
              const vm = this
              vm.$nextTick(()=>{
                vm.qtySizeChanged(index)
              })
            }
          }
        }
      }
      this.details = imported
      this.$refs.itemModal.hide()
    },
    qtySizeChanged(key){
      const fd = JSON.parse(JSON.stringify(this.details[key]))
      this.details[key].qty_system = sumBy(fd.sizes,'qty_system')
      this.details[key].qty_opname = sumBy(fd.sizes,'qty_opname')
      this.details[key].price_total = Math.abs( sumBy(fd.sizes,'qty_system') - sumBy(fd.sizes,'qty_opname')) * fd.price
    }
  }
}
</script>

<style>

</style>