<template>
  <validation-provider
    :name="label || placeholder"
    :rules="rules"
    v-slot="validationContext"
    :vid="vid"
  >
    <b-form-group :label-cols-sm="leftLabel?4:0" :description="description" :label="label" :label-for="id">
      <b-form-input hidden :value="value" :aria-describedby="`${id}-feedback`" :state="getValidationState(validationContext)"></b-form-input>
      <c-cleave
        ref="clive"
        :id="id"
        :name="id"
        :value="value"
        @input="handleInput"
        @keyup.native="onChange"
        @focus.native="$refs.clive.$el.select()"
        v-bind="$attrs"
        v-on="$listeners"
        :options="cleaveOptions"
        :class="['form-control',`form-control-${size}`,{'is-invalid': getValidationState(validationContext)===false}]"
      ></c-cleave>
      <b-form-invalid-feedback :id="`${id}-feedback`">
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script>
import id from "vee-validate/dist/locale/id.json";
import { localize, extend, ValidationProvider } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import { v4 as uuidv4 } from "uuid";
import { BFormGroup, BFormInvalidFeedback,BFormInput } from "bootstrap-vue";
import CCleave from './cleave'

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});
localize("id", id);
extend('minimal', {
  validate(value, {min}) {
    return parseFloat(value)>=parseFloat(min)
  },
  params:['min'],
  message: "{_field_} minimal adalah {min}"
})
extend('maximal', {
  validate(value, {min}) {
    return parseFloat(value)<=parseFloat(min)
  },
  params:['min'],
  message: "{_field_} maksimal adalah {min}"
})

export default {
  name: "FormNumber",
  props: {
    componentName: String,
    label: String,
    description: String,
    placeholder: String,
    vid: String,
    size: String,
    value: [String, Number, Array, Object],
    rules: Object,
    leftLabel:Boolean
  },
  components: {
    BFormGroup,
    BFormInvalidFeedback,
    ValidationProvider,
    CCleave,
    BFormInput
  },
  data() {
    return {
      id: "",
      cleaveOptions:{
        numeral: true,
        numeralThousandsGroupStyle:'thousand',
        numeralDecimalScale: 4
      },
    };
  },
  methods: {
    handleInput(e) {
      this.$emit("input", e)
      this.$emit('onValueChanged', e)
    },
    onChange(){
      const d = this.value
      this.$emit('onChange', d)
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? (valid ? null : false) : null;
    },
    focus(){
      this.$refs.clive.$el.focus()
    }
  },
  created() {
    this.id = uuidv4();
  },
};
</script>

<style>
</style>