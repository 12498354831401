<template>
  <b-overlay :show="loading" rounded spinner-small spinner-variant="primary" :class="{'d-inline-block': $attrs.block===undefined}">
    <b-button class="padding-small" v-on="$listeners" v-bind="attrs" :variant="`${variant||'primary'}`">
      <slot></slot>
    </b-button>
  </b-overlay>
</template>

<script>
import {BButton,BOverlay} from 'bootstrap-vue'
export default {
  name:'CButton',
  props:{
    variant: String,
    loading: {
      type: Boolean,
      default: false
    },
  },
  components:{
    BButton,
    BOverlay
  },
  computed:{
    attrs(){
      const attrs = JSON.parse(JSON.stringify(this.$attrs))
      delete attrs.variant
      delete attrs.loading
      return attrs
    }
  },
}
</script>

<style lang="scss" scoped>
  .padding-small {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-right: 0.5rem;
  }
</style>