<template>
  <b-overlay :show="isloading" spinner-variant="primary" variant="transparent">
    <b-card-actions ref="cardactions" :border-variant="borderVariant" :badge="badge" :collapsed="collapsed" :title="title" no-body action-collapse>
      <b-card-header>
        <div></div>
        <div>
          <slot name="action"></slot>
        </div>
      </b-card-header>
      <b-card-body :key="cKey">
        <slot></slot>
      </b-card-body>
    </b-card-actions>
  </b-overlay>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {BCardBody,BOverlay,BCardHeader} from 'bootstrap-vue'
export default {
  name:'CCardAction',
  props:{
    title:{
      type: String,
      default: ''
    },
    isloading:{
      type: Boolean,
      default: false
    },
    collapsed:{
      type: Boolean,
      default: false
    },
    badge:{
      type: [Number,String]
    },
    borderVariant:String
  },
  data(){
    return {
      cKey: 0
    }
  },
  components:{
    BCardActions,
    BCardBody,
    BOverlay,
    BCardHeader
  },
  methods:{
    refreshCard(){
      this.cKey++
    }
  }
}
</script>

<style>

</style>