<template>
  <validation-provider :name="label||placeholder" :rules="rules" v-slot="validationContext">
    <b-form-group :label="(label||'')+(isRequired&&(label.length>0) ? ' *':'')" :label-for="id">
      <b-form-select :name="id" hidden :value="value" :options="options" :aria-describedby="`${id}-feedback`" :state="getValidationState(validationContext)"/>
      <v-select
      :clearable="clearable"
      append-to-body
      :calculate-position="withPopper"
      ref="vselect"
      v-bind="attrs"
      v-on="$listeners"
      :value="value"
      @input="handleInput"
      :label="selectLabel"
      :options="options"
      :class="[{'invalid': validationContext.errors[0]},classSize]"
      :placeholder="placeholder"
      >
        <template #no-options>
          <small class="font-italic">Tidak ada data {{label||placeholder}}...</small>
        </template>
        <template #spinner="{ loading }">
          <div v-if="loading" style="border-left-color: #3f6dd1" class="vs__spinner">
            <!-- The .vs__spinner class will hide the text for me. -->
          </div>
        </template>
      </v-select>
      <b-form-invalid-feedback :id="`${id}-feedback`">{{validationContext.errors[0]}}</b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script>
import id from "vee-validate/dist/locale/id.json"
import { localize, extend, ValidationProvider } from 'vee-validate';
import * as rules from "vee-validate/dist/rules";
import {BFormGroup,BFormInvalidFeedback,BFormSelect} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { v4 as uuidv4 } from 'uuid'
import isUndefined from 'lodash/isUndefined'
import { createPopper } from '@popperjs/core';

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
localize('id', id)

export default {
  name:'FormSelectStatic',
  props:{
    label:{
      type:String,
      default:''
    },
    placeholder:{
      type:String,
      default:''
    },
    clearable:{
      type:Boolean,
      default:false
    },
    value:[Object,Array],
    options:[Array],
    rules:Object,
    size:String,
    selectLabel:{
      type:String,
      default:'label'
    }
  },
  components:{
    BFormGroup,
    BFormSelect,
    ValidationProvider,
    BFormInvalidFeedback,
    vSelect,
  },
  data(){
    return {
      placement: 'bottom',
    }
  },
  computed:{
    attrs(){
      const attrs = JSON.parse(JSON.stringify(this.$attrs))
      delete attrs.rules
      delete attrs.size
      delete attrs.label
      return attrs
    },
    classSize(){
      if (!this.size) {
        return ''
      } else {
        return 'select-size-'+this.size
      }
    },
    isRequired(){
      const rule = this.rules
      if(isUndefined(rule)||isUndefined(rule.required)) return false
      else return this.rules.required
    }
  },
  methods:{
    handleInput(e){
      this.$emit('input', e)
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    withPopper(dropdownList, component, {width}) {
      dropdownList.style.width = width;

      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: 'offset', options: {
              offset: [0, -1]
            }
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn ({state}) {
              component.$el.classList.toggle('drop-up', state.placement === 'top')
            },
          }]
      });
      return () => popper.destroy();
    }
  },
  created(){
    this.id = uuidv4()
  }

}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  .invalid .vs__dropdown-toggle {
    border-color: #ea5455;
  }
  .valid .vs__dropdown-toggle {
    border-color: #28c76f;
  }
  .padding-select-small {
    padding: 10px 15px;
  }
</style>