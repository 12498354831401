<template>
  <b-row>
    <b-col sm="12" xl="8">
      <c-card-action title="Filter" :badge="$store.getters['filter/isItemsFiltered'] ? 'Filtered':''" collapsed>
        <c-form ref="filterForm" @submit="filterSubmit">
          <form-input v-model="itemsFilter.code" label="Kode"></form-input>
          <form-select-all multiple v-model="itemsFilter.category" label="Kategori Produk" endpoint="v1/item_categories/selectkat" nested></form-select-all>
          <form-select-all multiple v-model="itemsFilter.brand" label="Merek" endpoint="v1/brands/select2"></form-select-all>
          <b-form-group label="Stok" label-for="igroup">
            <b-input-group id="igroup">
              <b-input-group-prepend>
                <b-form-select v-model="itemsFilter.stock_term" text-field="label" value-field="value" :options="stockTerms"></b-form-select>
              </b-input-group-prepend>
              <b-form-input type="number" v-model="itemsFilter.stock"></b-form-input>
            </b-input-group>
          </b-form-group>
          <form-select-static v-if="!onlyActiveFilter" clearable :options="statuses" v-model="itemsFilter.status" label="Status"></form-select-static>
          <b-button class="mr-1" type="submit" variant="primary">Sortir</b-button>
          <b-button @click="resetFilter" variant="outline-warning">Reset</b-button>
        </c-form>
      </c-card-action>
    </b-col>
    <b-col sm="12">
      <c-card title="Daftar">
        <template slot="action">
          <b-button v-if="!isModal" @click="printLabelPrompt" variant="primary" class="mr-1">Cetak Label</b-button>
          <b-button :target="isModal ? '_blank' : '_self'" v-if="cP(44)" :to="{name:'items-create'}" variant="primary"><feather-icon icon="PlusIcon"></feather-icon> Tambah</b-button>
        </template>
        <div>
          <div class="d-flex justify-content-between mb-1">
            <div>
              <small class="padding">Tampilkan </small>
              <label>
                <b-form-select
                  @input="getData()"
                  v-model="pageLength"
                  :options="lengthArray"
                />
              </label>
              <small class="padding"> Entri</small>
            </div>
            <div class="w-25">
              <b-input-group>
                <b-form-input @input="getData()" v-model="search" type="search" placeholder="Cari..."></b-form-input>
                <b-input-group-append is-text>
                  <feather-icon icon="SearchIcon"></feather-icon>
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>
          <div v-if="isloading" class="text-center mt-1 mb-1">
            <b-spinner variant="primary" type="grow"></b-spinner>
          </div>
          <b-table-simple v-else responsive outlined hover small>
            <b-thead>
              <b-tr>
                <b-th><input @change="isCheckAllItemEvent" type="checkbox" v-model="isCheckAllItem" id="isCheckAllItem"> Nama Produk</b-th>
                <b-th width="5"></b-th>
                <b-th class="text-center">Kode Variasi</b-th>
                <b-th>Variasi</b-th>
                <b-th v-if="!nonSale">Harga Jual</b-th>
                <b-th>Harga Beli</b-th>
                <b-th>Stok</b-th>
                <b-th v-if="!isModal" :width="15">Status</b-th>
              </b-tr>
            </b-thead>
            <b-tbody class="fixed-height">
              <template v-for="(value,key) in items">
                <b-tr :key="key">
                  <b-td class="w-25" :rowspan="value.item_variants.length+1">
                    <div class="">
                      <b-form-checkbox class="mb-1" v-model="value.checked" @change="oncheckvariant($event,key)">Pilih Semua</b-form-checkbox>
                      <h5 class="font-weight-bolder">{{value.code}}</h5>
                      <h6 class="font-weight-bolder">{{value.brand_name}}</h6>
                      <h6>{{value.category_name}}</h6>
                      <span class="small font-underline">Deskripsi :</span>
                      <p class="small">{{value.description}}</p>
                    </div>
                  </b-td>
                  <b-td>
                    <b-form-checkbox v-model="value.item_variants[0].checked"></b-form-checkbox>
                  </b-td>
                  <b-td class="small"><span class="font-weight-bolder">1.</span> {{value.item_variants[0].code}}</b-td>
                  <b-td class="small">{{value.item_variants[0].size_name}}, {{value.item_variants[0].color_name}}</b-td>
                  <b-td v-if="!nonSale" class="small">Rp.{{value.item_variants[0].sale_price | formatNumber}}</b-td>
                  <b-td class="small">Rp.{{value.item_variants[0].purchase_price | formatNumber}}</b-td>
                  <b-td class="small">{{value.item_variants[0].stock | formatNumber}}</b-td>
                  <b-td v-if="!isModal" class="text-center" :rowspan="value.item_variants.length+1">
                    <b-button-group size="sm">
                      <b-button v-if="cP(46)" variant="primary" :to="{name:'items-edit',params:{id:value.id}}"><feather-icon icon="EditIcon"></feather-icon></b-button>
                      <b-button v-if="cP(47)" variant="danger" @click="confirmDelete(value)"><feather-icon icon="TrashIcon"></feather-icon></b-button>
                    </b-button-group>
                  </b-td>
                </b-tr>
                <b-tr v-show="k>0" v-for="(detail,k) in value.item_variants" :key="'vr'+detail.id">
                  <b-td>
                    <b-form-checkbox v-model="detail.checked"></b-form-checkbox>
                  </b-td>
                  <b-td class="small">
                    <span class="font-weight-bolder">{{k+1}}.</span> {{detail.code}}
                  </b-td>
                  <b-td class="small">{{detail.size_name}}, {{detail.color_name}}</b-td>
                  <b-td v-if="!nonSale" class="small">Rp.{{detail.sale_price | formatNumber}}</b-td>
                  <b-td class="small">Rp.{{detail.purchase_price | formatNumber}}</b-td>
                  <b-td class="small">{{detail.stock | formatNumber}}</b-td>
                </b-tr>
                <b-tr v-if="!value.is_appended" @click="appendVariant(key)" :key="`adds`+key">
                  <b-td colspan="7" class="text-center">
                    <b-link class="small font-italic">Show More...</b-link>
                  </b-td>
                </b-tr>
                <b-tr v-else @click="prependVariant(key)" :key="`less${key}`">
                  <b-td colspan="7" class="text-center">
                    <b-link class="small font-italic">Show Less...</b-link>
                  </b-td>
                </b-tr>
              </template>
            </b-tbody>
          </b-table-simple>
          <div class="d-flex justify-content-between">
            <span class="padding small">Menampilkan {{items.length}} dari {{total}} data.</span>
            <b-pagination v-model="page" :per-page="pageLength" :total-rows="total" ></b-pagination>
          </div>
        </div>
      </c-card>
      <div v-if="isModal" class="d-flex justify-content-end">
        <b-button variant="primary" @click="importModalExe">Import</b-button>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import CCard from '@/utils/components/CCard.vue'
import {BPagination,BLink,BButton,BFormCheckbox,BFormGroup,BInputGroup,BInputGroupPrepend,BInputGroupAppend,BFormSelect,BFormInput,BTableSimple,BThead,BTr,BTh,BTbody,BTd,BButtonGroup,BSpinner} from 'bootstrap-vue'
import CCardAction from '@/utils/components/CCardAction.vue'
import CForm from '@/utils/components/CForm.vue'
import FormInput from '@/utils/components/FormInput.vue'
import { mapFields } from 'vuex-map-fields'
import FormSelectAll from '@/utils/components/FormSelectAll.vue'
import map from 'lodash/map'
import join from 'lodash/join'
import FormSelectStatic from '@/utils/components/FormSelectStatic.vue'
import debounce from 'lodash/debounce'
import uniqBy from 'lodash/uniqBy'
import filter from 'lodash/filter'
import flatMap from 'lodash/flatMap'

export default {
  props:{
    isModal:Boolean,
    nonSale:Boolean,
    onlyActiveFilter:Boolean
  },
  components:{
    CCard,
    BButtonGroup,
    BButton,
    CCardAction,
    CForm,
    FormInput,
    FormSelectAll,
    FormSelectStatic,
    BLink,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormSelect,
    BFormInput,
    BFormCheckbox,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
    BInputGroupAppend,
    BPagination,
    BSpinner
  },
  data(){
    const fields = [
      {key:'code',label:'Nama Produk',class:'w-25',sortable:true},
      {key:'name',label:'Kode Variasi',sortable:true,tdClass:'align-top'},
      {key:'description',label:'Variasi',sortable:true,tdClass:'align-top'},
      {key:'category_name',label:'Harga Jual',sortable:true,tdClass:'align-top'},
      {key:'brand_name',label:'Harga Beli',sortable:true,tdClass:'align-top'},
      {key:'stock',label:'Stok',sortable:true,tdClass:'align-top'},
      {key:'is_active',label:'Status',sortable:true,tdClass:'align-top'},
      {key:'id',label:'',tdClass:'d-flex justify-content-end align-middle'},
    ]
    const statuses = [
      {value:1,label:'Aktif'},
      {value:0,label:'Non-Aktif'},
    ]
    const stockTerms = [
      {value:'eq',label:'='},
      {value:'ne',label:'!='},
      {value:'gt',label:'>'},
      {value:'gte',label:'>='},
      {value:'lt',label:'<'},
      {value:'lte',label:'<='},
    ]
    const items = []
    const lengthArray = [
      10,
      25,
      50,
      100
    ]
    return {
      fields,
      statuses,
      stockTerms,
      items,
      pageLength: 10,
      lengthArray,
      search:'',
      total:0,
      page:1,
      total_pages:1,
      sortKey:'',
      sortDesc:false,
      isloading:true,
      isCheckAllItem:false
    }
  },
  computed:{
    ...mapFields('filter',['itemsFilter']),
    filterParams(){
      const dt = JSON.parse(JSON.stringify(this.itemsFilter))
      let fd = {}
      if(dt.code) fd.code = dt.code
      if(dt.category.length>0) fd.id_category = join(map(dt.category,n => n.value),',')
      if(dt.brand.length>0) fd.id_brand = join(map(dt.brand,n => n.value),',')
      if(dt.stock_term) fd.stock_term = dt.stock_term
      if(dt.stock) fd.stock = dt.stock
      if(dt.status) fd.id_status = dt.status.value
      return fd
    },
    endpoint(){
      if(this.isModal) return 'v1/items/modal'
      else return 'v1/items/index'
    },
    selectedItem(){
      return filter(flatMap(this.items,n => (n.item_variants)),n => (n.checked))
    }
  },
  watch:{
    page(){
      const vm = this
      vm.getData()
    },
    pageLength(){
      const vm = this
      vm.page = 1
      vm.getData()
    }
  },
  methods:{
    confirmDelete(item){
      // this.confirmation(`submitDelete`,`Anda akan menghapus Pemasok <b>${item.name}</b>`,`Apakah Anda Yakin ?`,{id:item.id,name:item.name})
      this.confirmation(`submitDelete`,{text:`Anda akan menghapus Produk <b>${item.code}</b>`,parameter:{id:item.id,code:item.code}})
    },
    async submitDelete({id,code}){
      try {
        await this.$http.delete(`v1/items/delete/`,{params:{id,code}})
        this.notify(`Data Berhasil Dihapus`,`OK`)
        this.getData()
      } catch (error) {
        this.handleError(error)
      }
    },
    filterSubmit(){
      this.getData()
    },
    resetFilter(){
      const vm = this
      vm.$store.commit('filter/RESET_ITEMS')
      vm.$nextTick(()=>{
        this.getData()
      })
    },
    importModalExe(){
      const data = this.items
      this.$emit('importingfn', data)
    },
    async getData(){
      this.onLoad(this)
    },
    onLoad: debounce((vm) => {
      vm.isloading = true
      let endpoint = vm.endpoint
      const params = {
        search: vm.search,
        page: vm.page,
        per_page: vm.pageLength,
        sort: vm.sortKey,
        order: vm.sortDesc ? 'desc':'asc',
        ...vm.filterParams
      }
      vm.$http.get(endpoint,{params:params}).then(e => {
        const {data,total,total_pages} = e.data
        let items = []
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          items.push({...element,...{checked: false}})
        }
        vm.items = items
        vm.total = total
        vm.total_pages = total_pages
        vm.isloading = false
      }).catch(()=>{
        vm.isloading = false
      })
    },200),
    async appendVariant(key){
      const {id} = this.items[key]
      const params = JSON.parse(JSON.stringify(this.filterParams))
      params.id = id
      params.page = 2
      params.order = params.order ? params.order : "asc",
      params.code = params.code ? params.code : "",
      params.id_category = params.id_category ? params.id_category : "",
      params.id_brand = params.id_brand ? params.id_brand : "",
      params.stock_term = params.stock_term ? params.stock_term : "",
      params.stock = params.stock ? params.stock : "",
      params.id_status = params.id_status ? params.id_status : "",
      params.sort = params.sort ? params.sort : "name",
      params.search = params.search ? params.search : ""

      const {data} = await this.$http.get(`v1/items/variant`,{params})
      const items = JSON.parse(JSON.stringify(this.items[key].item_variants))
      let mapping = []
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        mapping.push({...element,is_add: true})
      }

      this.items[key].item_variants = uniqBy([...items,...mapping],'id')
      this.items[key].is_appended = true
    },
    prependVariant(key){
      const dt = JSON.parse(JSON.stringify(this.items[key].item_variants))
      this.items[key].item_variants = filter(dt,n => (!n.is_add))
      this.items[key].is_appended = false
    },
    oncheckvariant(value,key) {
      const fr = JSON.parse(JSON.stringify(this.items[key].item_variants))
      const changed = map(fr,n => {
        if(value) return {...n,checked:true}
        else return {...n,checked:false}
      })
      this.items[key].item_variants = changed
    },
    printLabelPrompt(){
      const data = this.selectedItem
      if(data.length==0) return this.notify("Silahkan memilih minimal 1 varian.","Oops!","danger")
      const names = join(map(data,'code'),', ')
      const ids = join(map(data,'id'),',')
      this.confirmation('printLabel',{parameter:ids,text:`Anda akan mencetak label dengan kode varian : <b>${names}</b>`})
    },
    printLabel(id){
      const self = this
      this.$http.get(`v1/item_variants/barcode`,{params:{id}}).then(({data}) => {
        self.$parent.qzPrint(data)
      })
    },
    isCheckAllItemEvent() {
      const { isCheckAllItem, items } = this
      if(isCheckAllItem) {
        let exe = []
        for (let i = 0; i < items.length; i++) {
          // const {id} = items[i];
          exe.push(this.appendVariant(i))
        }
        this.isloading = true
        Promise.all(exe).then(() => {
          for (let i = 0; i < items.length; i++) {
            // const element = items[i];
            this.items[i].checked = true
            this.oncheckvariant(true, i)
          }
          this.isloading = false
        })
        .catch(()=>{
          this.isloading = false
        })
      } else {
        for (let i = 0; i < items.length; i++) {
          // const element = items[i];
          this.items[i].checked = false
          this.oncheckvariant(false, i)
        }
      }
    }
  },
  mounted(){
    this.getData()
  },
  created(){
    if(this.onlyActiveFilter) this.itemsFilter.status = {value:1,label:"Aktif"}
  }
}
</script>

<style lang="scss">
  p.nowrap {
    white-space: nowrap;
  }
  .fixed-height tr > td {
    height: 2px;
  }
</style>